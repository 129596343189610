<template>
  <div>
    <loading-indicator />
  </div>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import axios from 'axios'

export default {
  name: 'SetLocalStorage',
  components: {
    LoadingIndicator,
  },
  data () {
    return {
      prevRoute: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    keyName () {
      return this.$route.query.l_s_keyname
    },
    keyValue () {
      return this.$route.query.l_s_keyvalue
    },
  },
  mounted () {
    if (this.keyName && this.keyValue) {
      localStorage.setItem(this.keyName, this.keyValue)
      console.log('set local storage:',this.keyName, localStorage.getItem(this.keyName))
      if (this.keyName === 'access_token.bbridge_reach') {
        axios.defaults.headers.common['Authorization'] = ''
        delete axios.defaults.headers.common['Authorization']
        if (this.keyValue) {
          axios.defaults.headers.common['Authorization'] = `${this.keyValue}`
        }
      }
    }
    // find the previous route name, and route query parameters
    // if ther is a previous route name, then, go to that route, with teh query params that were passed in
    // if there is no previous route name, then, go to the home page
    let params = this.prevRoute.query || {}
    // remove the l_s_keyname and l_s_keyvalue from the query params if they exist
    if (params.l_s_keyname) {
      delete params.l_s_keyname
    }
    if (params.l_s_keyvalue) {
      delete params.l_s_keyvalue
    }
    if (this.prevRoute && this.prevRoute.name) {
      this.$router.push({ name: this.prevRoute.name, query: params })
    } else {
      this.$router.push({ name: 'Home' })
    }
  },
}
</script>
